import { useLoaderData } from "remix";
import PostIndex from "~/components/PostIndex";
import type { Post } from "~/post";
import { getPosts } from "~/post";
import type { MetaFunction } from "remix";

export const loader = () => {
  return getPosts();
};

export const meta: MetaFunction = () => {
  return {
    title: `Ryan Chenkie`,
  };
};

export default function Index() {
  const posts = useLoaderData<Post[]>();
  return (
    <>
      <div style={{ fontFamily: "system-ui, sans-serif", lineHeight: "1.4" }}>
        <PostIndex posts={posts} />
      </div>
    </>
  );
}
